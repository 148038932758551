<script lang="ts">
</script>

<svelte:head>
  <title>Not Found | marc.cx</title>
  <meta name="description" content="Page not found">

  <script>
    window.not_found = true;
  </script>
</svelte:head>

A matching route was not found.

<style lang="scss">
</style>
